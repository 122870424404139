import { Component, Fragment } from 'react';

import { brandConfig } from 'brands';
import { addMinutes, format } from 'date-fns';
import { get, isEqual, uniqBy } from 'lodash';
import { queryCache } from 'react-query';

import { PrivacyPolicyModal } from 'components/PrivacyPolicyModal';
import InvoiceTotal from 'components/invoices/InvoiceTotal';
import SubmitStepper from 'components/steps/SubmitStepper';
import Steps from 'components/steps/SubmitSteps';
import { Messages } from 'consts/Messages';
import { INVOICE_STATUS, INVOICE_ITEM_TYPES } from 'consts/constants';
import { INVOICE } from 'consts/routing';
import { handleProjectsAssignments, matchProjectsWithHarvestData } from 'helpers/harvest';
import {
  handleProductiveProjectsAssignments,
  matchProjectsWithProductiveData,
} from 'helpers/productive';
import { createRange, formatDate, normalizeISODate } from 'utils/datesHelper';
import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';
import { getEffectiveRate } from 'utils/getEffectiveRate';
import { setStateAsync } from 'utils/helpers';
import { isNewProjectExpensesEnabled } from 'utils/netsuiteIntegration';
import { hasUserFilledBanking } from 'utils/profileBankingHelper';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { withSubmitData } from './SubmitDataProvider';

export class Submit extends Component {
  reminderToastId = null;
  userDisplayNameReminderToastId = null;
  incompleteBankingInfoToastId = null;

  state = {
    step: 1,
    invoice: null,
    originalInvoice: null,
    showEditPayment: false,
    harvestAPIData: null,
    harvestFetchError: null,
    isLoadingHarvestData: false,
    productiveEnabled: false,
    productiveAPIData: null,
    productiveFetchError: null,
    isLoadingProductiveData: false,
    showPrivacyPolicyModel: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEqual(nextProps.invoice, prevState.originalInvoice)) {
      return null;
    }

    return {
      invoice: nextProps.invoice,
      originalInvoice: nextProps.invoice,
    };
  }

  getShouldSkipSurvey() {
    return !brandConfig.flags.invoiceSurveyEnabled || this.props.shouldSkipSurvey;
  }

  isNewExpensesEnabled = isNewProjectExpensesEnabled();

  getHarvestAPITimeEntries = async (data) => {
    this.setState({ isLoadingHarvestData: true });

    const start = formatDate(normalizeISODate(new Date(data.value.start)), 'yyyy-MM-dd');
    const end = formatDate(normalizeISODate(new Date(data.value.end)), 'yyyy-MM-dd');

    if (this.props.user.rate !== null) {
      await this.props
        .getHarvestTimeEntriesActionCreator({
          body: {
            harvestId: data.harvestId,
            start,
            end,
          },
        })
        .then((response) => {
          if (response.payload.data) {
            this.setState(
              { harvestAPIData: response.payload.data, isLoadingHarvestData: false },
              () => this.createInvoiceItemsForHarvestProjects(response.payload.data),
            );
          } else {
            this.setState({ harvestFetchError: 'Harvest API fetch() failed!' });
          }
        })
        .catch((error) => {
          this.setState({ harvestFetchError: error.message });
        });
    } else {
      this.setState({
        harvestFetchError:
          'The user.rate is null. Please contact your account manager to add your rate to the database.',
      });
    }
    return;
  };

  getProductiveAPITimeEntries = async (data) => {
    this.setState({ isLoadingProductiveData: true });

    const start = formatDate(normalizeISODate(new Date(data.value.start)), 'yyyy-MM-dd');
    const end = formatDate(normalizeISODate(new Date(data.value.end)), 'yyyy-MM-dd');

    await this.props
      .getProductiveTimeEntriesActionCreator({
        body: {
          productiveId: data.productiveId,
          start,
          end,
        },
      })
      .then((response) => {
        if (response.payload.data) {
          this.setState(
            { productiveAPIData: response.payload.data, isLoadingProductiveData: false },
            () => this.createInvoiceItemsForProductiveProjects(response.payload.data),
          );
        } else {
          this.setState({ productiveFetchError: 'Productive API fetch() failed!' });
        }
      })
      .catch((error) => {
        this.setState({ productiveFetchError: error.message });
      });
    return;
  };

  /**
   * Creates invoiceItems for a Harvest user based on how many matching projects were found.
   *
   * @param calculatedProjectDetails
   */
  createInvoiceItemsForHarvestProjects = (calculatedProjectDetails) => {
    const sortedHarvestProjects = this.getSortedHarvestProjects();
    const matchingProjects = matchProjectsWithHarvestData(
      this.props.projects,
      calculatedProjectDetails,
    );
    handleProjectsAssignments(sortedHarvestProjects, matchingProjects, {
      onRemove: ({ invoiceItem }) => {
        this.removeInvoiceItem(invoiceItem.id);
      },
      onUpdate: ({ invoiceItem, quantity }) => {
        this.updateInvoiceItem(invoiceItem.id, { ...invoiceItem, quantity });
      },
      onCreate: (data) => {
        this.createInvoiceItem({ ...data, _category: this.props.hoursInvoiceCategory[0] });
      },
    });
  };

  createInvoiceItemsForProductiveProjects = (calculatedProjectDetails) => {
    const sortedProductiveProjects = this.getSortedProductiveProjects();
    const matchingProjects = matchProjectsWithProductiveData(
      this.props.projects,
      calculatedProjectDetails,
    );
    const { invoice } = this.props;
    handleProductiveProjectsAssignments(sortedProductiveProjects, matchingProjects, {
      onRemove: ({ invoiceItem }) => {
        this.removeInvoiceItem(invoiceItem.id);
      },
      onUpdate: ({ invoiceItem, quantity }) => {
        this.updateInvoiceItem(invoiceItem.id, {
          ...invoiceItem,
          quantity: invoiceItem.isDetachedFromProductive ? invoiceItem.quantity : quantity,
        });
      },
      onCreate: (data) => {
        const {
          _project: { id: dataProjectId },
          quantity,
        } = data;
        const duplicate =
          invoice._invoiceItems.length &&
          invoice._invoiceItems.find((item) => item._project.id === dataProjectId);
        if (duplicate) {
          this.updateInvoiceItem(duplicate.id, { ...duplicate, quantity });
          return;
        }
        this.createInvoiceItem({ ...data, _category: this.props.hoursInvoiceCategory[0] });
      },
    });
  };

  isHoursItem = (item) =>
    this.isNewExpensesEnabled
      ? item._expenseCategory?.name.includes('Hours Worked')
      : get(item, '_category.id') === get(this.props, 'hoursInvoiceCategory')[0]?.id;

  getSortedProjects = () => {
    const { invoice, assignments } = this.props;
    const hoursInvoiceItems = invoice._invoiceItems.filter(this.isHoursItem);
    const tempAssignments = [];
    const activeProjects = assignments
      .filter((assignment) => assignment.isActive)
      .filter((assignment) => assignment?._project?.canSubmitHoursInvoices)
      .map((assignment) => {
        const { allowsNonBillable, _allowedExpenses } = assignment._project;
        const hoursWorkedCategories = _allowedExpenses?.filter(
          (category) => category.name.includes('Hours Worked') && category.isActive,
        );

        let expenseCategory = null;
        let expenseCategory2 = null;
        assignment.rate = getEffectiveRate(invoice.dateTo, assignment._history, assignment.rate);

        if (this.isNewExpensesEnabled && allowsNonBillable && !!hoursWorkedCategories[0]) {
          expenseCategory = hoursWorkedCategories[0];
        }
        if (this.isNewExpensesEnabled && allowsNonBillable && !!hoursWorkedCategories[1]) {
          expenseCategory2 = hoursWorkedCategories[1];
          tempAssignments.push({
            assignment,
            expenseCategory: expenseCategory2,
            hoursWorkedCategories,
            tempId: `${assignment?._project?.id}-${expenseCategory2.id}`,
          });
        }

        return this.isNewExpensesEnabled
          ? {
              assignment,
              expenseCategory,
              hoursWorkedCategories,
              tempId: `${assignment?._project?.id}-${expenseCategory?.id || 0}`,
            }
          : { assignment };
      });

    const uniqueProjects = this.isNewExpensesEnabled
      ? uniqBy([...activeProjects, ...tempAssignments], 'tempId')
      : uniqBy(activeProjects, 'assignment._project.id');

    for (const invoiceItem of hoursInvoiceItems) {
      const item = this.isNewExpensesEnabled
        ? uniqueProjects.find(
            (item) =>
              item.tempId ===
              `${invoiceItem._project.id}-${
                item.tempId.includes('-0') ? 0 : invoiceItem._expenseCategory?.id
              }`,
          )
        : uniqueProjects.find((item) => item.assignment._project.id === invoiceItem._project.id);
      if (item) {
        item.invoiceItem = invoiceItem;
        if (!brandConfig.isXWP && item.assignment.rate !== invoiceItem.rate) {
          this.updateInvoiceItem(invoiceItem.id, {
            ...invoiceItem,
            rate: item.assignment.rate,
            usdPrice: item.assignment.rate,
            _category: item._expenseCategory,
          });
        }
      }
    }

    uniqueProjects.sort((a, b) =>
      a.assignment._project.name.localeCompare(b.assignment._project.name),
    );

    return uniqueProjects;
  };

  getSortedHarvestProjects = () => {
    const { invoice, assignments } = this.props;
    const { harvestAPIData, isLoadingHarvestData } = this.state;
    const hoursInvoiceItems = invoice._invoiceItems.filter(this.isHoursItem);
    if (!harvestAPIData || isLoadingHarvestData) {
      return [];
    }

    const activeHarvestProjects = assignments
      .filter((assignment) => assignment.isActive)
      .filter((assignment) => assignment?._project?.harvestId)
      .filter((assignment) => assignment?._project?.canSubmitHoursInvoices)
      .map((assignment) => ({ assignment }));

    const uniqueHarvestProjects = uniqBy(activeHarvestProjects, 'assignment._project.id');

    uniqueHarvestProjects.map((assignment) => {
      return harvestAPIData.find((project) => {
        if (project.projectId.toString() === assignment.assignment._project.harvestId) {
          assignment.taskBreakdownSummedByCategory = project.taskBreakdownSummedByCategory;
          assignment.totalHours = project.totalHours;
        }
        return null;
      });
    });

    for (const invoiceItem of hoursInvoiceItems) {
      const item = uniqueHarvestProjects.find(
        (item) => item.assignment._project.id === invoiceItem._project.id,
      );
      if (item) {
        item.invoiceItem = invoiceItem;
      }
    }

    uniqueHarvestProjects.sort((a, b) =>
      a.assignment._project.name.localeCompare(b.assignment._project.name),
    );
    return uniqueHarvestProjects;
  };

  removeHarvestInvoiceItems = () => {
    const { invoice } = this.props;
    const sortedHarvestProjects = this.getSortedHarvestProjects();
    if (invoice?._invoiceItems?.length) {
      return invoice._invoiceItems.map((invoiceItem) => {
        const activeHarvestInvoiceItem = sortedHarvestProjects.some(
          (project) =>
            project.assignment._project.id === invoiceItem._project.id &&
            project.taskBreakdownSummedByCategory,
        );
        if (!activeHarvestInvoiceItem && invoiceItem._project.harvestId) {
          return this.removeInvoiceItem(invoiceItem.id);
        } else {
          return null;
        }
      });
    }
  };

  getSortedProductiveProjects = () => {
    const { invoice, assignments } = this.props;
    const { productiveAPIData, isLoadingProductiveData } = this.state;
    const hoursInvoiceItems = invoice._invoiceItems.filter(this.isHoursItem);
    if (!productiveAPIData || isLoadingProductiveData) {
      return [];
    }
    const activeProductiveProjects = assignments
      .filter((assignment) => assignment.isActive)
      .filter((assignment) => assignment?._project?.productiveId)
      .filter((assignment) => assignment?._project?.canSubmitHoursInvoices)
      .map((assignment) => ({ assignment }));

    const uniqueProductiveProjects = uniqBy(activeProductiveProjects, 'assignment._project.id');
    uniqueProductiveProjects.map((assignment) => {
      return productiveAPIData.find((project) => {
        if (project.projectId.toString() === assignment.assignment._project.productiveId) {
          assignment.taskBreakdownSummedByCategory = project.taskBreakdownSummedByCategory;
          assignment.totalHours = project.totalHours;
        }
        return null;
      });
    });

    for (const invoiceItem of hoursInvoiceItems) {
      const item = uniqueProductiveProjects.find(
        (item) => item.assignment._project.id === invoiceItem._project.id,
      );
      if (item) {
        item.invoiceItem = invoiceItem;
        if (invoiceItem.isDetachedFromProductive) {
          item.quantity = invoiceItem.quantity;
        }
      }
    }

    uniqueProductiveProjects.sort((a, b) =>
      a.assignment._project.name.localeCompare(b.assignment._project.name),
    );

    return uniqueProductiveProjects;
  };

  removeProductiveInvoiceItems = () => {
    const { invoice } = this.props;
    const sortedProductiveProjects = this.getSortedProductiveProjects();
    if (!invoice?._invoiceItems?.length) {
      return null;
    }
    return invoice._invoiceItems.map((invoiceItem) => {
      const activeProductiveInvoiceItem = sortedProductiveProjects.some(
        (project) =>
          project.assignment._project.id === invoiceItem._project.id &&
          project.taskBreakdownSummedByCategory,
      );
      if (!activeProductiveInvoiceItem && invoiceItem._project.productiveId) {
        return this.removeInvoiceItem(invoiceItem.id);
      }
      return null;
    });
  };

  updateInvoiceReducer = (invoiceData) => (state) => {
    return { invoice: { ...state.invoice, ...invoiceData } };
  };

  updateInvoice = async (invoiceData) => {
    try {
      if (this.props.invoice?.id) {
        const response = await setStateAsync(this, this.updateInvoiceReducer(invoiceData), () =>
          this.props.updateInvoiceApiActionCreator({
            params: { invoiceId: this.props.invoice.id },
            body: this.state.invoice,
          }),
        );

        this.setState({ invoice: response.payload.data });
        await this.props.getUserCurrentInvoiceApiActionCreator({
          params: { userId: this.props.user.id },
          body: { createDraft: true },
        });

        if (invoiceData.dateFrom) {
          await this.props.getUnleashBudgetForDate(invoiceData.dateFrom);
        }

        return { invoiceData };
      }
    } catch (err) {
      const errorMessage = get(err, 'payload.message') || 'Failed to update the invoice';
      this.props.addToast({ text: errorMessage });
      return { error: errorMessage };
    }
  };

  createInvoiceItem = async (newInvoiceItemData) => {
    const { invoice, user } = this.props;
    const response = await this.props.postInvoiceItemApiActionCreator({
      body: {
        ...newInvoiceItemData,
        _invoice: invoice,
      },
    });
    const newInvoiceItem = response.payload.data;

    return new Promise((resolve) => {
      this.setState(
        (oldState) => ({
          invoice: {
            ...oldState.invoice,
            _invoiceItems: [...oldState.invoice._invoiceItems, newInvoiceItem],
          },
        }),
        () => {
          resolve(newInvoiceItem);
          this.props.getUserCurrentInvoiceApiActionCreator({
            params: { userId: user.id },
            body: { createDraft: true },
          });
        },
      );
    });
  };

  updateInvoiceItem = async (id, newInvoiceItemData) => {
    const { user } = this.props;
    const response = await this.props.putInvoiceItemApiActionCreator({
      params: { invoiceItemId: id },
      body: newInvoiceItemData,
    });
    const newInvoiceItem = response.payload.data;
    return new Promise((resolve) => {
      this.setState(
        (oldState) => {
          const { invoice } = oldState;
          invoice._invoiceItems = invoice._invoiceItems.map((item) => {
            if (item.id === id) return newInvoiceItem;
            return item;
          });
          return { invoice };
        },
        () => {
          resolve(newInvoiceItem);
          this.props.getUserCurrentInvoiceApiActionCreator({
            params: { userId: user.id },
            body: { createDraft: true },
          });
        },
      );
    });
  };

  removeInvoiceItem = async (id) => {
    return new Promise((resolve) => {
      // set invoice item status "deleting"
      this.setState(
        (oldState) => {
          const { invoice } = oldState;
          invoice._invoiceItems = invoice._invoiceItems.map((item) => {
            if (item.id === id) return { ...item, deleting: true };
            return item;
          });
          return { invoice };
        },
        async () => {
          const { user } = this.props;
          await this.props.deleteInvoiceItemApiActionCreator({ params: { invoiceItemId: id } });
          // removing item from list
          this.setState(
            (oldState) => {
              const { invoice } = oldState;
              invoice._invoiceItems = invoice._invoiceItems.filter((item) => item.id !== id);
              return { invoice };
            },
            () => {
              resolve();
              this.props.getUserCurrentInvoiceApiActionCreator({
                params: { userId: user.id },
                body: { createDraft: true },
              });
            },
          );
        },
      );
    });
  };

  getInvoiceTotal = () => {
    const {
      invoice: { _invoiceItems },
    } = this.state;

    if (!_invoiceItems?.length) {
      return 0;
    }

    return _invoiceItems.reduce((carry, item) => {
      const { quantity, rate } = item;
      const itemTotal = quantity * rate;

      return carry + itemTotal;
    }, 0);
  };

  updateUser = async (newData) => {
    return this.props
      .updateUserDataActionCreator({ ...this.props.user, ...newData }, 'accounting')
      .catch((error) => {
        this.showToast(Messages.ToastMessages.Accounting.failedValidation(error.payload.message));
      });
  };

  submit = async () => {
    if (this.getShouldSkipSurvey()) {
      const response = await this.props.patchUserCurrentInvoiceApiActionCreator({
        params: { userId: this.props.user.id },
        body: { action: 'submit' },
      });

      try {
        const jobs = queryCache.getQueryData(['/job-openings'])?.data ?? [];

        this.props.history.replace({
          pathname: jobs?.length
            ? `${INVOICE.REFERRALS_PATH}/${response.payload.data.id}`
            : `${INVOICE.CONFIRMATION_PATH}/${response.payload.data.id}`,
          state: {
            fromSurvey: true, // Simulate coming from survey
          },
        });
      } catch (e) {
        console.error(e);
      }
      return;
    }

    this.props.history.push(INVOICE.SURVEY_PATH);
  };

  selectStep = (step, dateRange) => async () => {
    if (step === 2 && dateRange) {
      const DATE_FMT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
      const offset = new Date().getTimezoneOffset();
      // persist the invoice draft in database (only if coming from step 1)
      this.props.getUserCurrentInvoiceApiActionCreator({
        params: { userId: this.props.user.id },
        body: {
          createDraft: true,
          dateRange: {
            startDate: format(addMinutes(dateRange.start, offset * -1), DATE_FMT),
            endDate: format(addMinutes(dateRange.end, offset * -1), DATE_FMT),
          },
        },
      });
    }
    return this.setState({ step });
  };

  showToast = (toastMessageData) => {
    const { payload } = this.props.addToast(toastMessageData);

    return payload.id;
  };

  getSubmittedInvoicesDateRanges = () => {
    const { userInvoices = [], canSubmitDuplicateInvoicePeriod = false } = this.props;
    return !canSubmitDuplicateInvoicePeriod
      ? userInvoices
          .filter((userInvoice) =>
            userInvoice?._invoiceItems?.some(
              (item) => item?._type?.id === INVOICE_ITEM_TYPES.DEDUCTION,
            ),
          )
          .filter((userInvoice) => userInvoice.dateFrom !== userInvoice.dateTo)
          .map(({ dateFrom, dateTo }) => {
            return dateFrom && dateTo ? createRange(new Date(dateFrom), new Date(dateTo)) : {};
          })
          .sort((a, b) => a.start - b.start)
      : [];
  };

  componentDidMount() {
    const { user } = this.props;

    this.reminderToastId = this.showToast(Messages.ToastMessages.Invoice.onTimePayment());

    this.userDisplayNameReminderToastId =
      this.getShouldSkipSurvey() && user.displayName
        ? this.showToast(Messages.ToastMessages.Invoice.currentSubmitting(user.displayName))
        : null;

    this.incompleteBankingInfoToastId = !hasUserFilledBanking(user)
      ? this.showToast(Messages.ToastMessages.Invoice.incompleteBankingInfo(user.displayName))
      : null;

    const shouldSkipRateValidation = user?.allow0Rate && !user?.rate;

    if (!user?.rate && !shouldSkipRateValidation) {
      this.showToast(Messages.ToastMessages.Invoice.rateNotSet);
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    [
      this.reminderToastId,
      this.userDisplayNameReminderToastId,
      this.incompleteBankingInfoToastId,
    ].forEach((item) => {
      if (item) {
        this.props.removeToast(item);
      }
    });
  }

  render() {
    const {
      user,
      users,
      assignments,
      hoursInvoiceCategory,
      referralInvoiceCategory,
      unleashPlusInvoiceCategory,
      invoiceCategories,
      unleashBudget,
      projects,
      harvestEnabled,
      productiveEnabled,
      history,
    } = this.props;
    const jobs = queryCache.getQueryData(['/job-openings'])?.data ?? [];
    const {
      invoice,
      step,
      harvestAPIData,
      harvestFetchError,
      isLoadingHarvestData,
      productiveAPIData,
      productiveFetchError,
      isLoadingProductiveData,
    } = this.state;

    if (!invoice) {
      return null;
    }

    const stepGeneralProps = {
      invoice,
      user,
      users,
      hoursInvoiceCategory,
      referralInvoiceCategory,
      unleashPlusInvoiceCategory,
      assignments,
      unleashBudget,
      invoiceCategories,
      projects,
      harvestAPIData,
      harvestFetchError,
      isLoadingHarvestData,
      productiveAPIData,
      productiveFetchError,
      isLoadingProductiveData,
      submittedInvoicesDateRanges: this.getSubmittedInvoicesDateRanges(),
      currentStep: step,
      selectStep: this.selectStep,
      updateInvoice: this.updateInvoice,
      updateInvoiceItem: this.updateInvoiceItem,
      removeInvoiceItem: this.removeInvoiceItem,
      createInvoiceItem: this.createInvoiceItem,
      removeHarvestInvoiceItems: this.removeHarvestInvoiceItems,
      removeProductiveInvoiceItems: this.removeProductiveInvoiceItems,
      updateUser: this.updateUser,
      getHarvestAPITimeEntries: this.getHarvestAPITimeEntries,
      getProductiveAPITimeEntries: this.getProductiveAPITimeEntries,
      isHoursItem: this.isHoursItem,
      getSortedProjects: this.getSortedProjects,
      getSortedHarvestProjects: this.getSortedHarvestProjects,
      getSortedProductiveProjects: this.getSortedProductiveProjects,
      onDateChange: this.onDateChange,
      shouldSkipSurvey: this.getShouldSkipSurvey(),
      harvestEnabled:
        !isFeatureEnabled(FEATURE_FLAGS.productive) &&
        harvestEnabled &&
        user.canUseHarvest &&
        user.harvestId &&
        invoice._status &&
        invoice._status.name === INVOICE_STATUS.DRAFT,
      productiveEnabled:
        isFeatureEnabled(FEATURE_FLAGS.productive) &&
        productiveEnabled &&
        user.canUseProductive &&
        user.productiveId &&
        invoice._status &&
        invoice._status.name === INVOICE_STATUS.DRAFT,
      history,
    };

    const steps = [Step1, Step2, Step3, Step4, Step5];
    const totalSteps = jobs?.length ? steps.length * 2 : steps.length;
    const invoiceTotal = this.getInvoiceTotal();
    const currency = get(invoice, '_currency.name');

    const acceptedPrivacyPolicy = async (accepted) => {
      if (accepted) {
        await this.updateUser({ acceptedPrivacyPolicy: true });
      }
      this.setState((state) => ({ ...state, showPrivacyPolicyModel: false }));
    };

    return (
      <Fragment>
        {!user.acceptedPrivacyPolicy && this.state.showPrivacyPolicyModel && (
          <PrivacyPolicyModal isOpen={true} onClose={acceptedPrivacyPolicy}></PrivacyPolicyModal>
        )}
        <Steps>
          <SubmitStepper
            shouldSkipSurvey={this.getShouldSkipSurvey()}
            shouldSkipReferrals={!jobs?.length}
            activeStep={0}
            stepProgress={step / totalSteps}
          />
          {steps.map((Step, index) => (
            <Step key={`Step${index + 1}`} {...stepGeneralProps} handleSubmit={this.submit} />
          ))}
          <InvoiceTotal total={invoiceTotal} currency={currency} />
        </Steps>
      </Fragment>
    );
  }
}

export default withSubmitData(Submit);
