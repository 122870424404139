import { brandConfig } from 'brands';

import { AssignmentHistory } from 'types/types';

/**
 * Checks if the actual invoice dateTo is lower than the effectiveDate of the last rate update applied.
 * If it is lower will return the old rate value.
 *
 * @param {string} dateTo - The invoice date to compare against in string format (YYYY-MM-DD).
 * @param {AssignmentHistory[]} _history - The array of assignment history objects.
 * @param {number} rate - The default rate to return if conditions aren't met.
 * @returns {number} The calculated rate based on invoice date and last rate update effective date.
 */

export const getEffectiveRate = (
  dateTo: string,
  _history: AssignmentHistory[],
  rate: number,
): number => {
  const hasHistory = _history?.length > 0;
  const hasEffectiveDate = hasHistory && Boolean(_history[_history?.length - 1]?.effectiveDate);
  const effectiveDate = hasHistory && new Date(_history[_history?.length - 1]?.effectiveDate || '');
  const invoiceDateTo = new Date(dateTo);

  if (!brandConfig.isXWP && hasHistory && hasEffectiveDate && effectiveDate > invoiceDateTo) {
    return parseInt(_history[_history?.length - 1]?.oldValue);
  }
  return rate;
};
