import { AnchorHTMLAttributes, ButtonHTMLAttributes, memo } from 'react';

import cs from 'classnames';

import Loader from 'components/Loader';
import { Icon } from 'design-system/Atoms/Icon';

import styles from './button.module.scss';

interface CommonProps {
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'cancel'
    | 'tertiary'
    | 'icon'
    | 'icon-red'
    | 'icon-primary'
    | 'icon-secondary'
    | 'icon-tertiary';
  layout: 'autolayout' | 'fluid';
  iconName?: string;
  iconPosition?: 'start' | 'end';
  iconClassName?: string;
  isLoading?: boolean;
  disableSvg?: boolean;
}
interface AnchorOnlyProps extends CommonProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  as: 'a';
}
interface ButtonOnlyProps extends CommonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
  type?: 'button' | 'submit' | 'reset';
}

export type ButtonProps = AnchorOnlyProps | ButtonOnlyProps;

const isAnchorProps = (props: ButtonProps): props is AnchorOnlyProps => {
  return props.as === 'a';
};

const isButtonProps = (props: ButtonProps): props is ButtonOnlyProps => {
  return props.as === 'button';
};

export const Button = memo<ButtonProps>(({ iconPosition, iconName, iconClassName, ...props }) => {
  const classnames = cs(styles.button, props.className, {
    [styles['layout--autolayout']]: props.layout === 'autolayout',
    [styles['layout--fluid']]: props.layout === 'fluid',
    [styles['variant--primary']]: props.variant === 'primary',
    [styles['variant--secondary']]: props.variant === 'secondary',
    [styles['variant--success']]: props.variant === 'success',
    [styles['variant--cancel']]: props.variant === 'cancel',
    [styles['variant--tertiary']]: props.variant === 'tertiary',
    [styles['variant--icon']]: props.variant === 'icon',
    [styles['variant--icon-red']]: props.variant === 'icon-red',
    [styles['variant--icon-primary']]: props.variant === 'icon-primary',
    [styles['variant--icon-secondary']]: props.variant === 'icon-secondary',
    [styles['variant--icon-tertiary']]: props.variant === 'icon-tertiary',
    [styles['icon-position--start']]: props.children && (!iconPosition || iconPosition === 'start'),
    [styles['icon-position--end']]: props.children && iconPosition === 'end',
    [styles['disabled--svg']]: props.disableSvg,
  });
  const iconClassnames = cs(styles.icon, iconClassName);

  const inner = (
    <>
      {iconName && (
        <Icon data-testid="TestId__BUTTONICON" className={iconClassnames} name={iconName} />
      )}
      <p>{props.children}</p>
    </>
  );

  if (isAnchorProps(props)) {
    return (
      <a data-testid="TestId__BUTTONASANCHOR" {...props} className={classnames}>
        <>{inner}</>
      </a>
    );
  }

  if (isButtonProps(props)) {
    return (
      <button data-testid="TestId__BUTTONASBUTTON" {...props} className={classnames}>
        <>{props.isLoading ? <Loader /> : inner}</>
      </button>
    );
  }
  return null;
});
